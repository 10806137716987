<template>
  <div style="width: 100vw;height: 100vh;background-color: #F3F5F9;">
            <div class="rightView"></div>
            <img class="loginBackImg" src="../../assets/loginRight.png">
            <!-- <img class="loginLogo" src="../../assets/loginLogo.png"> -->
    <div
        style="box-sizing: border-box;padding-left: 60px;padding-right:60px;margin-left: 16vw;width: 33vw;height: 100%;display: flex;flex-direction: column;justify-content: center;background-color: white;">


      <div class="login-title-big">Remote Exam</div>

      <div class="login-title" style="margin-top: 30px">{{$t('m.loginTitle')}}</div>
      <input class="login-input" v-model="form.account_name"/>
      <div class="login-title" style="margin-top: 30px">{{'OTP Code'}}</div>
      <div style="display: flex;align-items: flex-end">
        <input class="login-input" type="password" v-model="form.account_pwd"/>
        <el-button  class="send_code" type="primary" size="small" @click="sendCode">Send Code</el-button>
      </div>

      <div style="margin-top: 40px;position: relative">
        <div style="position: absolute;right: 0">
          <img @click="submit" src="../../assets/login-arrow.png" style="cursor: pointer;width: 3vw;height: auto">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {loginByEducator, loginByStudent, loginByEmployee,createLoginOPT,loginByStudentOptCode} from '../../api/index'
import {
  setUserId,
  setUserName,
  setUserType,
  getProjectCode,
  setApplicationExtraEvents,
  setAppId,
    setAccountName,
  setApplicationEvents, setAvatarUrl,logout,getProjectLanguage
} from '../../utils/store'
import '../../assets/common/font.css'
export default {
  name: 'Login',
  data() {
    return {
      form: {
        account_name: '',
        account_pwd: '',
        type: '学生'
      },
      showChinese:getProjectLanguage()

    }
  },
  mounted() {
    //先清除一下数据
    logout()
  },
  methods: {

    sendCode(){
      if (this.form.account_name == "") {
        this.$message.error("Please enter your email");
        return;
      }
      createLoginOPT(this.form.account_name).then((res) => {
        let result = res.data
        if(result.code === 0){
          this.$message.success('Sent Successfully');
        }else{
          this.$message.error('Verification code sent failed. Please try again.')
        }
      })
    },


    submit() {
      if (!this.form.account_name) {
        this.$message.error('Please input email')
        return
      }

      if (!this.form.account_pwd && this.form.type !== '学生') {
        this.$message.error('Please input password')
        return
      }

      if (!this.form.account_pwd && this.form.type === '学生') {
        this.$message.error('Please input opt code')
        return
      }

      if (this.form.type === '讲师') {
        loginByEducator(this.form.account_name, this.form.account_pwd).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: 'login success',
              type: 'success'
            })
            setUserId(res.data.data._id.$id)
            setUserName(res.data.data.educator_first_name + ' ' + res.data.data.educator_last_name)
            setUserType('educator')
            this.$router.push('/teacherlist')
          } else {
            this.$message.warning('login fail')
          }
        })
      } else if (this.form.type === '学生') {

        loginByStudentOptCode(this.form.account_name, this.form.account_pwd).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: 'login success',
              type: 'success'
            })
            var result = res.data.data
            var application_extra_events= result.application_extra_events
            setApplicationExtraEvents(application_extra_events)
            var application_events= result.application_events
            setApplicationEvents(application_events)
            var app_id = result._id.$id
            setAppId(app_id)
            var account_name = result['student_info']['account_name']
            setAccountName(account_name)
            var avatar_url = result['student_info']['avatar_url']
            setAvatarUrl(avatar_url)
            setUserId(res.data.data.student_id)
            setUserType('student')
            var student_name = res.data.data.student_info.student_lastName + ' ' + res.data.data.student_info.student_givenName
            setUserName(student_name)
            this.$router.push('/home')
          } else {
            this.$message.warning('Login failed: ' + res.data.msg)
          }
        })
      } else if (this.form.type === '助教') {
        loginByEmployee(this.form.account_name, this.form.account_pwd).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: 'login success',
              type: 'success'
            })
            setUserId(res.data.data._id.$id)
            setUserType('employee')
            setUserName(res.data.data.user_lastName + ' '+res.data.data.user_givenName)
            this.$router.push('/employeelist')
          } else {
            this.$message.warning('login fail')
          }
        })
      }

    }
  }
}
</script>

<style lang="less">

.send_code{
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.loginBackImg {
  // 1650 × 2028
  width: 40%;
  // height: 100%;
  position: absolute;
  z-index: 4;
  top: 0;
  align-items: center;
  right: 0;
  // left: 0;
}
.rightView {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 30%;
  background-color: #4468EC;
}
.loginLogo {
  width: 220px;
  position: absolute;
  z-index: 4;
  top: 100px;
  left: 150px;
}

.login-title-big {
  position: absolute;
  color: #4468EC;
  // background-color: #E74646;
  font-size: 43px;
  font-weight: 600;
  font-family: "Montserrat-SemiBold";
  text-align: center;
  top: 100px;
  left: 16vw;
  width: 33vw;
}
.login-title {
  color: #273161;
  font-size: 0.728vw;
  font-weight: 400;
  font-family: "PingFang SC";
}
.login-input {
  margin-top: 2px;
  box-sizing: border-box;
  border: 0px solid transparent;
  border-bottom-width: 2px;
  border-bottom-color:#273161;
  color: #273161;
  font-size: 1.1vw;
  width: 100%;
  height: 3vw;
  background-color: transparent;
  outline: none;
  border-radius: 0px;
  padding: 0 40px;
}
.login-input1 {
  margin-top: 2px;
  box-sizing: border-box;
  border: 0px solid transparent;
  border-bottom-width: 2px;
  border-bottom-color:#333333;
  color: #333333;
  font-size: 1.1vw;
  width: 100%;
  height: 3vw;
  background-color: transparent;
  outline: none;
  border-radius: 0px;
  padding: 0 0 0 25px;
}
.loginInput {
  background-color: transparent;
}

.el-select-dropdown__item.selected {
  color: rgb(243, 152, 0);
  font-weight: 700;
}
.el-select{
    width: 100%;
    color: #333333;
    display: inline-block;
    input{
        font-size: 1.1vw;
        border: none;
        background: none;
        text-align: left;
        color: #333333;
        // font-weight: bold;
    }
    .el-input__suffix{
        i{
            color: transparent;
            margin-left: 0.06rem;
            font-weight: bold;
        }
    }
    /* WebKit browsers */
    ::-webkit-input-placeholder {
        color: transparent;
        font-weight: bold;
    }
}

.loginContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .loginBackImg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
  }

  .loginContentView {
    width: 70%;
    height: 70%;
    background: rgba(255, 255, 255);
    border-radius: 28px;
    position: relative;

    .loginContentContainer {
      width: 100%;
      position: absolute;
      z-index: 3;

      .content {
        display: flex;
        width: 90%;
        margin: 0 auto;

        .left {
          width: 50%;
          position: relative;

          .loginTitle {
            font-size: 4vh;
            margin-top: 10vh;
            text-align: center;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #002A38;
          }

          .loginInputView {
            margin: 5vh auto;
            width: 72%;
          }

          .loginBtn {
            width: 72%;
            height: 5vh;
            line-height: 5vh;
            text-align: center;
            margin: 6vh auto;
            background: #E74646;
            border-radius: 10px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            font-size: 2vh;
            cursor: pointer;
          }

          .loginBtn:hover {
            opacity: 0.8;
          }

          .el-input__inner {
            border-radius: 10px !important;
            /* border: none; */
            height: 5vh;
            line-height: 5vh;
            font-size: 2vh;
            padding: 0 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #888888;
          }

          .el-input.is-active .el-input__inner, .el-input__inner:focus {
            border-color: rgb(243, 152, 0);
            outline: 0;
          }

          .el-input__icon {
            line-height: 5vh;
          }
        }

        .right {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .logo {
            width: 60%;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }

    .loginContentImg {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
    }
  }
}

</style>
